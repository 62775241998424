import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/webprojectcard.css"
import Particle12 from "../components/Particle12"
import SEO from "../components/SEO"
import "../css/dsa.css"
import { Link } from "gatsby"

const stack = () => {
    return (
        <div>
            <Layout>
                <SEO
                    title="Stack Programs"
                    description="Programs of Stack under Data Structures and Algorithm with Python."
                />
                <Particle12></Particle12>
                <h6>s</h6>

                <div className="programming-project">
                    <h1>Machine Learning Models</h1>
                </div>

                <div className="favp noSelect">
                    <h3>
                        Those projects having a{" "}
                        <span role="img" aria-label="Heart">
                            ❤️
                        </span>{" "}
                        on them... come under the Favourite category.{" "}
                    </h3>
                </div>

                <section className="most-liked noSelect">
                    <h3 className="total">Total Models :- 16</h3>
                    <div className="row">
                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">Covid-19</h3>
                                </div>
                                <p className="basic-op">
                                    •Create a model to predict whether you have an coronavirus infection or not (or probability of having infection)
                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/Covid%2019%20-%20Random%20Data"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">USA_Housing-Linear Regression</h3>
                                </div>
                                <p className="basic-op">
                                    •Create a model that allows to put in a few features of a house and returns back an estimate of what the house would sell for.
                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/USA_Housing-Linear%20Regression"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">Ecommerce Customers-Linear Regression</h3>
                                </div>
                                <p className="basic-op">
                                    •Create a model for a company to decide whether to focus their efforts on their mobile app experience or their website

                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/Ecommerce%20Customers-Linear%20Regression"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">Advertising-Logistic Regression</h3>
                                </div>
                                <p className="basic-op">
                                    •Create a model indicating whether or not a particular internet user clicked on an Advertisement
                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/Advertising-Logistic%20Regression"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">Titanic - Logistic Regression</h3>
                                </div>
                                <p className="basic-op">
                                    •Create a model to predict the survival or the death of a given passenger

                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/Titanic-Logistic%20Regression"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">Classified Data-K Nearest Neighbors</h3>
                                </div>
                                <p className="basic-op">
                                    •Create a model that directly predicts a class for a new data point based off of the features for a company
                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/Classified%20Data-K%20Nearest%20Neighbors"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">K Nearest Neighbors Project</h3>
                                </div>
                                <p className="basic-op">
                                    •Create a model on artificial data that directly predicts a class for a new data point based off of the features for a company
                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/K%20Nearest%20Neighbors%20Project"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">Loan data - Decision Trees and Random Forests</h3>
                                </div>
                                <p className="basic-op">
                                    •Create a model predict whether or not the borrower paid back their loan in full

                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/Loan%20data-Decision%20Trees%20and%20Random%20Forests"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">Kyphosis-Decision Trees and Random</h3>
                                </div>
                                <p className="basic-op">
                                    •Create a model to predict if the Kyphosis would return after surgery.

                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/Kyphosis-Decision%20Trees%20and%20Random%20Forests"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">
                                        Iris - Support Vector Machines
                                    </h3>
                                </div>

                                <p className="basic-op">
                                    •Create a model to predict the class(type) of the flowers based on their parameters

                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/Iris-Support%20Vector%20Machines"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">
                                        Breast Cancer-Support Vector Machines
                                    </h3>
                                </div>
                                <p className="basic-op">
                                    •Create a that predicts if the cancer diagnosis is benign or malignant based on several features.

                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/Breast%20Cancer-Support%20Vector%20Machines"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">College Data - K Means Clustering</h3>
                                </div>
                                <p className="basic-op">
                                    •Create a model that attempt to use KMeans Clustering to cluster Universities into to two groups, Private and Public

                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/College_Data-K%20Means%20Clustering"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">
                                        K Means Clustering Model
                                    </h3>
                                </div>
                                <p className="basic-op">
                                    •Create a model to understand clustering using make_blob dataset

                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/K%20Means%20Clustering%20Model"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">
                                        Movies - Recommender Systems
                                    </h3>
                                </div>
                                <p className="basic-op">
                                    •Create a model that tells you what movies/items are most similar to your movie choice.

                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/Movies-Recommender%20Systems"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">Principal Component Analysis</h3>
                                </div>
                                <p className="basic-op">
                                    •Create a model to understand Principal Component Analysis

                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/Principal%20Component%20Analysis"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rotate">
                            <div className="coffee-card">
                                <div className="card-header">
                                    <h3 className="title-text">Portland-Time series prediction</h3>
                                </div>
                                <p className="basic-op">
                                    •Create a model for Time Series Analysis and forecasting using ARIMA and SARIMA

                                </p>
                                <div className="back">
                                    <div className="back-content">
                                        <div className="layer"></div>
                                        <h4 className="text">Checkout here...</h4>
                                        <div className="bttn-box">
                                            <a
                                                href="https://github.com/arpitsomani8/Machine-Learning-Models/tree/master/Portland-Time%20series%20prediction"
                                                className="btntt"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Github Code
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </Layout>
        </div>
    )
}

export default stack
